
import { useEffect, useReducer, useState } from "react";
import CurrencyFormat from "react-currency-format";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { useLocation, useNavigate } from "react-router-dom";
import FieldError from "../../component/FieldError";
import { storeTaxbill } from "./taxApi";
import { useMutation } from 'react-query';
import moment from "moment";
import { errorToast } from "utils/api/Api";

const initialState = {
    id:null,
    salesId:null,
    type:'BUY',
    companyName:'',
    productName:'',
    productType:'',
    productCount:0,
    date:new Date(),
    price:0,
    taxPrice:0,
    sumPrice:0,
    paydate:new Date()
};

function taxReducer(state, action){
    return {...state, ...action };
}

export const TaxBillForm = ({salesId, taxbill}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useReducer(taxReducer, initialState);
    const [errors, setErrors] = useState([]);

    const taxbillStore = useMutation(storeTaxbill, {
        onSuccess:()=>{
            if(salesId){
                navigate({ pathname: `/sales/view/${salesId}`, search:  location.search });
            }else{
                navigate({ pathname: `/taxbill`, search:  location.search });
            }
        },
        onError:errorToast
    });

    useEffect(() => {
        if(taxbill==null){
            setState({salesId});
            return;
        }else{
            setState({
                salesId,
                ...taxbill,
                date:(taxbill?.date===null)?null:new Date(taxbill.date),
                paydate:(taxbill?.paydate===null)?null:new Date(taxbill.paydate),
            });
        }        
    }, [salesId, taxbill]);
    

    function handleInput(event) {
        const target = event.target
        if(target.type==='checkbox'){
            setState({ [target.name]: target.checked?target.value:'' });
        }else{
            setState({ [target.name]: target.value });
        }
    }// end handleInput

    const onStore = (event)=>{
        if(taxbillStore.isLoading)return;
        const storeData = {...state}
        if(storeData.date!==null) storeData.date = moment(storeData.date).format('YYYY-MM-DD');
        if(storeData.paydate!==null) storeData.paydate = moment(storeData.paydate).format('YYYY-MM-DD');
        taxbillStore.mutate(storeData);
        event.preventDefault();
    }

    return (
        <div className="container m-5">
            <form onSubmit={onStore}>
             {salesId===undefined &&
            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">매출정보</label>
                <div className="col-sm-10">
                    <p className="help-block">매출정보가 있는 계산서는 매출현황-매출 에서 작성해주세요.</p>
                </div>
            </div>
            }

            <div className="mb-3 row">
                <label className="col-sm-2 col-form-label">종류</label>
                <div className="col-sm-10">
                    <FieldError name="type" errors={errors} >
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="type" id="typeBUY" value="BUY" checked={state.type==='BUY'} onChange={handleInput} />
                        <label className="form-check-label" htmlFor="typeBUY">매입</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="type" id="typeSALE" value="SALE" checked={state.type==='SALE'} onChange={handleInput} />
                        <label className="form-check-label" htmlFor="typeSALE">매출</label>
                    </div>
                    </FieldError>
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="companyName" className="col-md-2 control-label">상호</label>
                <div className="col-md-6">
                    <FieldError name="companyName" errors={errors} >
                        <input type="text" className="form-control" name="companyName" value={state.companyName} onChange={handleInput} />
                    </FieldError>
                </div>

                <label htmlFor="date" className="col-md-1 control-label">발행일자</label>
                <div className="col-md-3">
                    <DatePicker locale={ko} name="date" selected={state.date} onChange={(date) => setState({date:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="발행일자" />
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="product_name" className="col-md-2 control-label">품목</label>
                <div className="col-md-3">
                    <FieldError name="productName" errors={errors} >
                        <input type="text" className="form-control" name="productName" value={state.productName} onChange={handleInput} />
                    </FieldError>
                </div>

                <label htmlFor="product_type" className="col-md-1 control-label">규격</label>
                <div className="col-md-2">
                    <FieldError name="productType" errors={errors} >
                        <input type="text" className="form-control" name="productType" value={state.productType} onChange={handleInput} />
                    </FieldError>
                </div>

                <label htmlFor="product_count" className="col-md-1 control-label">수량</label>
                <div className="col-md-2">
                    <FieldError name="productCount" errors={errors} >
                        <input type="number" className="form-control" name="productCount" value={state.productCount} onChange={handleInput} />
                    </FieldError>
                </div>

            </div>

            <div className="mb-3 row">
                <label htmlFor="price" className="col-md-2 control-label">금액</label>
                <div className="col-md-3">
                    <FieldError name="price" errors={errors} >
                        <CurrencyFormat className="form-control" thousandSeparator={true}  
                            value={state.price} maxLength={12}
                            onValueChange={(values)=>{setState({price:values.value, sumPrice:Number(state.taxPrice)+Number(values.value)}) }} />
                    </FieldError>
                </div>
                <div className="col-md-3">
                    <FieldError name="taxPrice" errors={errors} >
                        <CurrencyFormat className="form-control" thousandSeparator={true}  
                            value={state.taxPrice} maxLength={12}
                            onValueChange={(values)=>{setState({taxPrice:values.value, sumPrice:Number(state.price)+Number(values.value)}) }} />
                    </FieldError>
                </div>
                <div className="col-md-1">
                    =
                </div>
                <div className="col-md-3">
                    <FieldError name="sumPrice" errors={errors} >
                        <CurrencyFormat className="form-control" thousandSeparator={true}  
                            value={state.sumPrice} maxLength={12}
                            onValueChange={(values)=>{setState({sumPrice:values.value})}} />
                    </FieldError>
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="paydate" className="col-md-2 control-label">결제일자</label>
                <div className="col-md-3">
                    <DatePicker locale={ko} name="paydate" selected={state.paydate} onChange={(date) => setState({paydate:date})} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="결제일자" />
                </div>
            </div>
            <div className="text-center m-4">
                <button className="btn btn-md btn-primary" type="submit">등록하기</button>
                &nbsp;
                <button type="button" className="btn btn-secondary" onClick={()=>navigate(-1)}>닫기</button>
            </div>
            </form>
      </div>

      
    );
}