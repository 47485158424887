import { useState } from "react";
import { LIBRARY_SERVICE_TYPES } from "../../const";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { deleteHistory, storeHistory } from "./historyApi";
import { errorToast } from "utils/api/Api";

const LibraryHistoryForm = ({history=null, isCreateForm=false, page=0})=>{
    const { libraryId } = useParams();
    const queryClient = useQueryClient();
    
    const [content, setContent] = useState('');
    const [formToggle, setFormToggle] = useState(false);
    const [service, setService] = useState('EBOOK');
    const [date, setDate] = useState(new Date());


    const historyDelete = useMutation(deleteHistory, {
        onSuccess:()=>{
            queryClient.invalidateQueries(['libraryHistoryList', libraryId, page]);
            setFormToggle(false);
        },
        onError:errorToast
    });
    const historyStore = useMutation(storeHistory, {
        onSuccess:()=>{
            queryClient.invalidateQueries(['libraryHistoryList', libraryId, page]);
            onCancel();
        },
        onError:errorToast
    });

    const onModify = ()=>{
        setFormToggle(true);
        if(history!==null){
            setContent(history.content);
            setService(history.service);
        }
    }

    const onCancel = ()=>{
        setFormToggle(false);
        setContent('');
    }

    const onStore = ()=>{
        if(historyStore.isLoading)return;
        const storeData = {
            id:(history===null)?null:history.id,
            libraryId,
            content, service, date
        }
        historyStore.mutate( storeData );
    }

    const onDelete = ()=>{
        if( historyDelete.isLoading )return;
        if( window.confirm('삭제하시겠습니까?') ){
            historyDelete.mutate(history.id);
        }
    }

    if( isCreateForm || formToggle ){
        return (
            <div className="historyForm">
                <div className="row m-1 options">
                    <div className="col-auto">
                        <select className="form-control" value={service} onChange={e=>setService(e.target.value)}>
                            <option value="">선택</option>
                            {
                                Object.keys(LIBRARY_SERVICE_TYPES).map((itemKey)=>{
                                    return <option value={itemKey} key={itemKey}>{LIBRARY_SERVICE_TYPES[itemKey]}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-auto">
                        <DatePicker locale={ko} name="date" selected={date} onChange={(date) => setDate(date)} dateFormat="yyyy-MM-dd" className="form-control" placeholderText="발행일자" />
                    </div>
                </div>
                <div className="input-group">
                    <textarea className="form-control" rows={4} value={content}  onChange={(e)=>setContent(e.target.value)}></textarea>
                    <div className="controls">
                        <button className="btn btn-primary" type="button" onClick={onStore}>저장</button>
                        <br />
                        <button className="btn btn-secondary" type="button" onClick={onCancel}>취소</button>
                    </div>
                </div>
            </div>
        )
    }

    if(history===null)return ;

    return (
        <div className={`card history mt-1 ${history.service}`}>
            <div className="card-header header">
                <span className="service">[{LIBRARY_SERVICE_TYPES[history.service]}]</span>
                <span className="date">{history.date}</span>
                <button className="control float-end btn btn-danger btn-sm" onClick={onDelete}>삭제</button>
                <button className="control float-end btn btn-secondary btn-sm" onClick={onModify}>수정</button>
                <span className="name float-end">{history.name}</span>
            </div>
            <div className="card-body" dangerouslySetInnerHTML={{__html:history.content.replace(/\n/gi, '<br />')}}>
            </div>
        </div>
    );
    
}

export default LibraryHistoryForm;